@import 'scss/variables';

body {
  .LearnCard {
    text-align: left;

    &:hover {
      > h4 {
        text-decoration: underline;
      }
    }

    .Image {
      margin-bottom: 25px;

      &.Rounded {
        img {
          border-radius: 16px;
        }
      }

      span {
        width: 100%;
      }

      img {
        max-height: inherit;
        object-fit: cover;
        object-position: center;
        width: 100%;

        @media (min-width: $sm) {
          height: 250px;
          object-fit: cover;
        }
      }
    }

    .Meta {
      margin-bottom: 15px;

      > span {
        position: relative;
        padding-right: 22px;
        font-size: 15px;
        line-height: 22px;
        color: $color-black4;

        &.Platform {
          font-weight: 600;
          color: $color-black2;

          img {
            margin-right: 10px;
            width: 12px;
            height: 12px;
          }
        }

        &:last-child {
          padding-right: 0;
        }

        &:not(:last-child)::after {
          content: '•';
          position: absolute;
          top: 50%;
          right: 7px;
          transform: translateY(-50%);
          font-size: 15px;
          line-height: 22px;
          color: $color-black4;
        }
      }
    }

    .Title {
      margin-bottom: 15px;
      color: $color-black2;
    }

    > p {
      font-size: 15px;
      line-height: 22px;
      color: $color-black2;
    }
  }
}
