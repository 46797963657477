@import 'scss/variables';

body {
  .NotFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 30px 15px;
    min-height: 75vh;
    text-align: center;

    .Image404 {
      margin-bottom: 20px;
      max-height: 100px;

      @media (min-width: $sm) {
        margin-left: 60px;
      }
    }

    .Title {
      margin-bottom: 10px;
      color: $color-blue;
    }

    .Subtitle {
      margin-bottom: 30px;
      color: $color-black4;
    }

    .Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1600px;
      gap: 32px;
      margin-bottom: 30px;
      border-bottom: 1px solid $color-black7;

      .Link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &:hover {
          p,
          i {
            color: $color-red5;
          }
        }
      }
    }

    .Articles {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 80px 48px;
      max-width: 1600px;

      @media (min-width: $sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $md) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: $xl) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
